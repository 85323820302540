import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Card, CloseButton } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import { BsChevronUp, BsChevronDown, BsSearch, BsX } from "react-icons/bs";
import { useState } from 'react';
import Layout from '../../components/Layout';
import LanguageContext from '../../helper/LanguageContext';
import { DE } from '../../Languages/DE';
import { EN } from '../../Languages/EN';
import { callAPI } from "../../helper/apiUtils";
import _ from "lodash";
import { BASE_URL, KJUUP_COLORS, TOGGLE_DRAWER } from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { convertBranchRole, convertCapitalLeter, convertPosition } from '../../helper/convertor';
import { BiAdjust, BiBell, BiExit, BiX, BiXCircle } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';
import { $user } from '../../helper/UserFactory';
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';

function ProjectMembers(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [viewLoader, setViewLoader] = useState(false)
    const user = useSelector((state) => state.user);
    const { isGerm } = React.useContext(LanguageContext);
    const navigateUserProfile = (userId) => {
        dispatch({ type: TOGGLE_DRAWER, open: true });
        history.push(`/user-profile/${userId}`);
    };
    return (
        <div class="w-300" style={{ position: "absolute", height: "100%", width: "100%", borderTopWidth: 0 }}>
            {viewLoader && (
                <div className="website_loader">
                    <div className="loader" />
                </div>
            )}
            <div style={{ borderBottom: "1px solid #dfdfdf", paddingBottom: 10 }}>
                <div onClick={(e) => props.handleEditClose()} style={{ padding: 0, margin: 0, display: 'flex', flexDirection: "row" }}>
                    <a
                        style={{
                            color: props.isInternal ? KJUUP_COLORS.internal : "#f08438",
                            padding: 0, margin: 0,
                            // position:"absolute"
                        }}
                    >
                        {!props.isInternal ?
                            <img
                                width={10}
                                src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                            />
                            :
                            <img
                                width={10}
                                className="custom-svg"
                                src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                            />
                        }
                    </a>
                    <div
                        style={{

                            fontSize: "18px",
                            color: "#000",
                            fontWeight: 500,
                            marginLeft: 10
                        }}
                    >
                        {isGerm?DE.room_members:EN.room_members}
                    </div>
                </div>
            </div>
            <div className="admin-content m-0">
                <div className="search_div" style={{ marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 10, border: '1px solid #dfdfdf', borderRadius: 5, overflow: "hidden" }}>
                    <div className="search">
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control
                                autocomplete="off"
                                value={props.searchUser}
                                type="search"
                                placeholder={isGerm ? DE.search : EN.search}
                                onChange={(e) => props.filterAdmins(e.target.value)}
                            />
                        </Form.Group>
                        <BsSearch />
                    </div>
                </div>
                {props.users?.map((user, index) => (
                    <div key={index}>
                        {user.names?.length > 0 && (
                            <h5
                                className="p-2 mb-0"
                                style={{ background: "#f7f7f7" }}
                            >
                                {user.name}
                            </h5>
                        )}
                        {user?.names?.map((u, i) => (
                            <div key={i} className="bg-white">
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        padding: 10,
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        <div
                                            className="admin-control"
                                            style={
                                                u.requestNotYetAccepted && {
                                                    opacity: "0.5",
                                                }
                                            }
                                        >
                                            <img
                                                className="m-0"
                                                width={47}
                                                height={47}
                                                style={{ borderRadius: 4 }}
                                                src={
                                                    u.avatar
                                                        ? BASE_URL + u.avatar
                                                        : process.env.PUBLIC_URL +
                                                        "/assets/img/head.svg"
                                                }
                                                alt="Generic placeholder"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={
                                            u.requestNotYetAccepted
                                                ? {
                                                    opacity: "0.5",
                                                    flex: 1,
                                                    paddingLeft: 10,
                                                }
                                                : {
                                                    flex: 1,
                                                    paddingLeft: 10,
                                                }
                                        }
                                    >
                                        <p
                                            className="small font-weight-bold m-0 p_user_name pointer"
                                            onClick={() =>
                                                navigateUserProfile(u?.id)
                                            }
                                        >
                                            {u.fullName
                                                ? convertCapitalLeter(u.fullName)
                                                : ""}
                                        </p>
                                        {u.requestNotYetAccepted && (
                                            <span style={{ fontSize: "12px" }}>
                                                {isGerm ? DE.PENDING : EN.PENDING}{" "}
                                            </span>
                                        )}
                                        <p className="m-0 small p_company">
                                            {u.companyName}
                                        </p>
                                        {!u.requestNotYetAccepted && (
                                            <div>
                                                <p className="m-0 small p_postion">
                                                    {isGerm ? DE.branch : EN.branch}:{" "}
                                                    <i>
                                                        {convertBranchRole(
                                                            u.buildingRole,
                                                            isGerm
                                                        )}
                                                    </i>
                                                </p>
                                                <p className="m-0 small p_postion">
                                                    {u.buldingAdmin
                                                        ? isGerm
                                                            ? DE.admin
                                                            : EN.admin
                                                        : isGerm
                                                            ? DE.member
                                                            : EN.member}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    {(props.projectDetails?.userRole === "admin" && !props.isDone) && (
                                        <div
                                            onClick={async () => {
                                                await $crud.confirm({
                                                    title: "",
                                                    textContent: isGerm
                                                        ? DE.remove_project_admin
                                                        : EN.remove_project_admin,
                                                    options: {
                                                        ok: isGerm
                                                            ? DE.confirm
                                                            : EN.confirm,
                                                        cancel: isGerm
                                                            ? DE.cancel
                                                            : EN.cancel,
                                                    },
                                                });
                                                props._deleteMemberFromProject(
                                                    u.id,
                                                    i,
                                                    index
                                                );
                                            }}
                                            style={{
                                                justifyContent: "center",
                                                paddingRight: 10,
                                            }}
                                        >
                                            <img
                                                width={18}
                                                className="pointer"
                                                onClick={(e) => { }}
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/img/trashcan.svg"
                                                }
                                                alt="Generic placeholder"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProjectMembers;

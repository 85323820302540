import React, { useEffect, useState, Fragment } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { BsArrowBarDown, BsChevronDown, BsChevronUp } from "react-icons/bs";
import Layout from "../../components/Layout";
import LanguageContext from "../../helper/LanguageContext";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import { Link, useHistory } from "react-router-dom";
import LocationSearchInput from "../../components/LocationSearchInput";
import { callAPI } from "../../helper/apiUtils";
import { $crud } from "../../helper/CrudFactory";
import BuildingTypesDialog from "./Dialogs/BuildingTypesDialog";
import CompanyListDialog from "./Dialogs/CompanyListDialog";
import ProjectMapComponent from "./ProjectMap/ProjectMapComponent";
import _ from "lodash";
import AutoComplete from "./Partial/AutoComplete";
import { Animated } from "react-animated-css";
import { OPEN_PROJECTS, store, UPDATE_PROJECT_ACTION_FIRE } from "../../store";
import Geocode from "react-geocode";
import { withRouter } from "react-router-dom";
import { KJUUP_COLORS, TOGGLE_DRAWER } from "../../helper/constants";
import { connect, useSelector } from "react-redux";
import { BiArrowToBottom } from "react-icons/bi";
import { convertBranchRole, convertBuildings, convertRole } from "../../helper/convertor";
export const GOOGLE_API_KEY = 'AIzaSyCc6D4Md2hNlwB3seL_fKKr8ugbZbOzem8';

Geocode.setApiKey("AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q");
function EditProject(props) {
    const storeOpenedProjects = useSelector(state => state.openProjects);
    const isInternal = props.isInternal
    let companyId = props.location.state?.companyId
    let companyName = props.location.state?.companyId
    const [params, setParams] = useState({
        name: "",
        projectNo: "",
        buildingType: [],
        areaOfActivity: "",
        street: "",
        postCode: "",
        city: "",
        country: "",
        lat: "",
        long: "",
        companyName: "",
        houseNo: "",
        isHidden: true,
        full_address: ""
    });
    const [selectedAddress, setSelectedAddress] = useState(false)
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        name: "",
        projectNo: "",
        buildingType: [],
        areaOfActivity: "",
        street: "",
        // postCode: "",
        city: "",
        country: "",
        lat: "",
        long: "",
        houseNo: "",
        full_address: ""
    });
    const [cameraModal, setCameraModal] = useState(false);
    const [street, setStreet] = useState('');
    const [house, setHouse] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [pNo, setPNo] = useState('');
    const [email, setEmail] = useState('');
    const [lat, setLat] = useState();
    const [long, setLong] = useState();
    const [full_address, setFullAddress] = useState('');
    const [countryName, setCountryName] = useState('');
    const [showDropdpownCity, setShowDropdpownCity] = useState(false)
    const [citySelected, setCitySelected] = useState([]);
    const [citySelectedAPIParam, setCitySelectedAPIParam] = useState([]);
    const [searchCity, setSearchCity] = useState('');
    const [mainVal, setMainVal] = useState('');
    const [showOptionalInfo, setShowOptionalInfo] = useState(true)
    const [paramsLoaded, setParamsLoaded] = useState({
        name: false,
        buildingType: false,
        areaOfActivity: false,
        projectNo: false,
    });
    const { isGerm } = React.useContext(LanguageContext);
    const [suggestionLoaded, setSuggestionLoaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [show, setShow] = useState(false);
    const [showCompanyDialog, setCompanyDialog] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [showOptionalInformation, setShowOptionalnformation] = useState(false)
    const [displayTypes, setDisplayTypes] = useState('');
    const [defultTyesArr, setDefaultsArr] = useState([]);
    const isAdmin = props.isAdmin
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const getList = async () => {
        try {
            const { data } = await callAPI(`/building/all?projectType=${isInternal ? "1" : "0"}`, "", "GET");
            console.log(data);
            if (data.status) {
                // setSuggestions(!isInternal? data.buildings:props.location.state?.projects);
                setSuggestions(!isInternal ? data.buildings : []);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const setParam = (name, value) => {
        setParams((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
        setErrors((prev) => {
            return {
                ...prev,
                [name]: "",
            };
        });
    };
    const setName = (name, value) => {
        if (value) {
            setParams((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
            setErrors((prev) => {
                return {
                    ...prev,
                    [name]: "",
                };
            });
        }
    };
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        //Firstname
        if (!params.name) {
            formIsValid = false;
            errors["name"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
        }

        // if (!params.areaOfActivity) {
        //   formIsValid = false;
        //   errors["areaOfActivity"] = isGerm? DE.invalid_fiels : EN.invalid_fiels;
        // }

        setErrors(errors);
        return formIsValid;
    };
    const history = useHistory();
    const [companies, setCompanies] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const getCompanies = async () => {
        const { data } = await callAPI(`/company/my`, {}, "GET");
        if (data.status) {
            if (data.companies.length === 1) {
                setParam("companyId", data.companies[0].id);
            } else {
                setCompanies(data.companies);
            }
        }
    };
    useEffect(() => {
        getCompanies();
        getBuildings();
        getList();
        getProjectDetails()
    }, [props.location]);
    const getProjectDetails = async () => {
        const id = props.project_id
        const { data } = await callAPI(`/building/one/${id}`, {
            id: id
        }, 'GET')
        console.log(data)

        if (data.status) {
            const suggestion = data;
            console.log(suggestion)
            setMainVal(suggestion.full_address)
            let types = _.isArray(suggestion.buildingType) ? suggestion.buildingType.join(', ') : suggestion.buildingType
            let tps = suggestion.buildingType
            let conveted = []
            if(tps && Array.isArray(tps))
            for (let obj of tps) {
                conveted.push(convertBuildings(obj.trim(), isGerm))
            }
            console.log(conveted, tps)
            setDisplayTypes(conveted ? conveted.join(', ') : types)
            setDefaultsArr(suggestion.buildingType)
            setParams(prev => {
                return {
                    ...prev,
                    id: suggestion.id,
                    name: suggestion.name,
                    userRole: suggestion.userRole,
                    projectNo: suggestion.projectNo,
                    buildingType: tps,
                    areaOfActivity: tps,
                    street: suggestion.street,
                    postCode: suggestion.postCode,
                    city: suggestion.city,
                    country: suggestion.country,
                    lat: suggestion.lat,
                    long: suggestion.long,
                    houseNo: suggestion.houseNo,
                    isHidden: suggestion.isHidden,
                    full_address: suggestion.full_address
                }
            })
        }
    }
    const submitProject = async () => {
        setLoading(true);
        let id = props.project_id
        console.log("params--<", params)
        if(!params.buildingType){
            params.buildingType = []
        }
        if (isInternal) {
            let errors = {}
            if (!params.name) {
                errors["name"] = isGerm ? DE.invalid_fiels : EN.invalid_fiels;
                setErrors(errors)
            }
            else {
                setErrors(errors)
                try {
                    const projectData = {
                        ...params,
                        "companyId": companyId,
                        "name": params.name,
                        "projectNo": params.projectNo,
                        "companyName": companyName
                    };
                    const { data } = await callAPI("/building", "", "PUT", projectData);
                    console.log(data);
                    if (!data.status) {
                        await $crud.notify({
                            type: "error",
                            message: data.message,
                        });
                    } else {
                        const updateProjects = storeOpenedProjects.map((p) => {
                            if (p.id === id) {
                                return { ...p, ...params };
                            } else {
                                return p;
                            }
                        });
                        store.dispatch({
                            type: OPEN_PROJECTS,
                            openProjects: updateProjects
                        });
                        store.dispatch({
                            type: UPDATE_PROJECT_ACTION_FIRE,
                            updateProjectActionFire: true
                        });
                        props.handleEditClose()
                    }
                } catch (e) {
                    console.log(e);
                }
                setLoading(false);
            }
        }
        else if (handleValidation()) {
            try {
                const { data } = await callAPI(`/building${params.companyId ? `?companyId=${params.companyId}` : ""}`, "", "PUT", params);
                if (!data.status) {
                    await $crud.notify({
                        type: "error",
                        message: data.message,
                    });
                } else {
                    const updateProjects = storeOpenedProjects.map((p) => {
                        if (p.id === id) {
                            return { ...p, ...params };
                        } else {
                            return p;
                        }
                    });
                    store.dispatch({
                        type: OPEN_PROJECTS,
                        openProjects: updateProjects
                    });
                    store.dispatch({
                        type: UPDATE_PROJECT_ACTION_FIRE,
                        updateProjectActionFire: true
                    });
                    props.handleEditClose()
                }
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        } else {

            console.log("errors", errors);
        }
    };

    useEffect(() => {
        if (!selectedAddress) {
            let fullAddress = `${params.street} ${params.city ? `, ${params.city}` : ""}`
            Geocode.fromAddress(fullAddress).then(
                (results) => {
                    console.log("results", results);
                    const { lat, lng } = results.results[0].geometry.location;

                    let country;
                    console.log("lat", lat, lng);
                    for (var i = 0; i < results.results[0].address_components.length; i++) {
                        for (
                            var j = 0;
                            j < results.results[0].address_components[i].types.length;
                            j++
                        ) {
                            if (
                                results.results[0].address_components[i].types[j] == "country"
                            ) {
                                country = results.results[0].address_components[i]?.long_name;
                            }
                        }
                    }
                    console.log("country", country)
                    setParam('lat', lat)
                    setParam('long', lng)
                    setParam('country', country)
                },
                (error) => {
                    console.error(error);
                }
            );
        }
        console.log("params", params.city, "street", params.street, "selectedAddress", selectedAddress)
    }, [params.city, params.street])

    const getSuggestion = async (suggestion) => {
        await setParams((prev) => {
            return {
                ...prev,
                name: suggestion.name ? suggestion.name : "",
                buildingType: suggestion.buildingType
                    ? _.isArray(suggestion.buildingType)
                        ? suggestion.buildingType.join(", ")
                        : suggestion.buildingType
                    : "",
                areaOfActivity: suggestion.buildingType
                    ? _.isArray(suggestion.buildingType)
                        ? suggestion.buildingType.join(", ")
                        : suggestion.buildingType
                    : "",
                street: suggestion.street,
                postCode: suggestion.postCode,
                city: suggestion.city,
                country: suggestion.country ? suggestion.country : "germany",
                lat: suggestion.lat,
                long: suggestion.long,
                houseNo: suggestion.houseNo,
            };
        });
        setParamsLoaded((prev) => {
            return {
                ...prev,
                name: !!suggestion.name,
                buildingType: !!suggestion.buildingType,
            };
        });
        setSuggestionLoaded(true);
    };

    const getBuildings = async () => {
        try {
            const { data } = await callAPI("/building/all", "", "GET");
            if (data.status) {
                setBuildings(data.buildings);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const handleHideProject = async (e) => {
        if (props.isAdmin) {
            if (!params?.isHidden) {
                let langText = isGerm ? DE.hide_project_popup_text : EN.hide_project_popup_text;
                await $crud.confirm({
                    title: "",
                    textContent: langText,
                    options: {
                        ok: "Confirm",
                        cancel: "Cancel"
                    }
                });
            }
            else {
                let langText = isGerm ? DE.unhidden_message : EN.unhidden_message;
                await $crud.confirm({
                    title: "",
                    textContent: langText,
                    options: {
                        ok: "Confirm",
                        cancel: "Cancel"
                    }
                });
            }
            setParams({ ...params, isHidden: !params.isHidden })
        }
    }

    return (
        <React.Fragment>

            <div className="w-300" style={{ height: "93vh", border: 0 }}>

                {/* <div className="map text-center">
              <a onClick={handleShow}>
                <img src={process.env.PUBLIC_URL + "/assets/img/Map.svg"} />{" "}
                {isGerm ? DE.map : EN.map}
              </a>
              <Modal
                size="xl"
                show={show}
                onHide={handleClose}
                animation={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isGerm ? DE.add_or_join_company : EN.add_or_join_company}
                    <br />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body
                  style={{ height: "80vh", width: "100%", padding: "0rem" }}
                >
                  <ProjectMapComponent
                    handleClose={handleClose}
                    setSuggestion={getSuggestion}
                    buildings={buildings}
                  />
                </Modal.Body>
              </Modal>
            </div>
            <div className="or">
              <span>{isGerm ? DE.or : EN.or}</span>
            </div> */}
                <div>
                    {/* <h3 className="details">{isGerm ? DE.details : EN.details}</h3> */}
                    <div style={{}}>
                        <div onClick={(e) => props.handleEditClose()} style={{ padding: 0, margin: 0, display: 'flex', flexDirection: "row" }}>
                            <a
                                style={{
                                    color: props.isInternal ? KJUUP_COLORS.internal : "#f08438",
                                    padding: 0, margin: 0,
                                    // position:"absolute"
                                }}
                            >
                                {!props.isInternal ?
                                    <img
                                        width={10}
                                        src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                                    />
                                    :
                                    <img
                                        width={10}
                                        className="custom-svg"
                                        src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                                    />
                                }
                            </a>
                            <div
                                style={{

                                    fontSize: "18px",
                                    color: "#000",
                                    fontWeight: 500,
                                    marginLeft: 10
                                }}
                            >
                                {props.name}
                            </div>
                        </div>
                    </div>
                    <Form
                        className="add_company_from"
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitProject();
                        }}
                    >
                        {isAdmin ?
                        <Form.Group controlId="formBasicEmail">
                            <AutoComplete
                                suggestions={suggestions}
                                setParam={setName}
                                value={params.name}
                                setSuggestion={getSuggestion}
                                locked={paramsLoaded.name || !isAdmin}
                            />
                            {errors.name && (
                                <small className="pl-2 text-danger">{errors.name}</small>
                            )}
                        </Form.Group>
                        :
                        <></>
                            }
                            {
                                !isAdmin && !isInternal?
                                <Form.Group controlId="formBasicPassword">
                                <Form.Control
                                    value={params.projectNo}
                                    onChange={(e) => setParam("projectNo", e.target.value)}
                                    type="text"
                                    placeholder={isGerm ? DE.project_No : EN.project_No}
                                />
                                {errors.projectNo && (
                                    <small className="pl-2 text-danger">
                                        {errors.projectNo}
                                    </small>
                                )}
                            </Form.Group>
                            :
                            <></>
                            }
                        {!isInternal &&
                            <>
                                {/* <div className="row">
                      <div className="col-md-8 pr-1">
                        <Form.Group controlId="formBasicPassword">
                          <LocationSearchInput
                            suggestionLoaded={
                              suggestionLoaded && params.street ? true : false
                            }
                            defaultValue={params.street}
                            placeHolder={isGerm ? DE.street : EN.street}
                            setParam={setParam}
                            setSelectedAddress={setSelectedAddress}
                          />
                          {errors.street && (
                            <small className="pl-2 text-danger">
                              {errors.street}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-4 pl-1">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            value={params.houseNo}
                            onChange={(e) => setParam("houseNo", e.target.value)}
                            placeholder={isGerm ? DE.house_number : EN.house_number}
                          />
                          {errors.houseNo && (
                            <small className="pl-2 text-danger">
                              {errors.houseNo}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    </div> */}
                                {/* <div className="row">
                      <div className="col-md-5 pr-1">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            value={params.postCode}
                            disabled={suggestionLoaded}
                            onChange={(e) => setParam("postCode", e.target.value)}
                            type="text"
                            placeholder={isGerm ? DE.postal_code : EN.postal_code}
                          />
                          {errors.postCode && (
                            <small className="pl-2 text-danger">
                              {errors.postCode}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-7 pl-1">
                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            value={params.city}
                            disabled={suggestionLoaded}
                            onChange={(e) => setParam("city", e.target.value)}
                            type="text"
                            placeholder={isGerm ? DE.city : EN.city}
                          />
                          {errors.city && (
                            <small className="pl-2 text-danger">
                              {errors.city}
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    </div> */}
                            </>
                        }
                        {isInternal ?
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control
                                    value={params.projectNo}
                                    onChange={(e) => setParam("projectNo", e.target.value)}
                                    type="text"
                                    placeholder={isGerm ? DE.project_No : EN.project_No}
                                />
                                {errors.projectNo && (
                                    <small className="pl-2 text-danger">
                                        {errors.projectNo}
                                    </small>
                                )}
                            </Form.Group>
                            :
                            <></>
                        }
                        {(!isInternal && isAdmin) &&
                            <>
                                <Card.Header style={{ padding: "0.40rem 0.80rem", borderBottom: "none" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }} className="project-checkbox">
                                        <span style={{ color: "#737373", fontSize: "14px", fontStyle: "italic" }}>
                                            {isGerm ? DE.hide_project : EN.hide_project}
                                        </span>
                                        <Form>
                                            <Form.Check
                                                checked={params?.isHidden}
                                                onChange={handleHideProject}
                                                type="switch"
                                                id="custom-switchx"
                                            // disabled={!isAdmin?true:false}
                                            />
                                        </Form>
                                    </div>
                                </Card.Header>
                            </>
                        }
                        {
                            (!isInternal && isAdmin) ?
                                <div onClick={() => {
                                    setShowOptionalnformation(!showOptionalInformation)
                                }} style={{ marginTop: 20, marginRight: 9, marginLeft: 9, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div style={{ flex: 1 }}>
                                        <h6>
                                            {isGerm ? DE.optional_information : EN.optional_information}
                                        </h6>
                                    </div>
                                    <div>
                                        {!showOptionalInformation ?
                                            <BsChevronDown
                                                size={15}
                                            />
                                            :
                                            <BsChevronUp
                                                size={15}
                                            />
                                        }
                                    </div>
                                </div>
                                :
                                <></>
                        }

                        {
                            (!isInternal && showOptionalInformation && isAdmin) ?
                                <>
                                    <Form.Group controlId="formBasicPassword11" style={{ marginTop: 10 }}>
                                        <LocationSearchInput
                                            custom
                                            defaultValue={full_address}
                                            placeHolder={isGerm ? DE.Address : EN.Address}
                                            setVal={(val) => {
                                                if (!val) {
                                                    setParam('full_address', '')
                                                }
                                                setMainVal(val)
                                                setSearchCity(val)
                                            }}
                                            val={mainVal}
                                            setFull={(val) => {
                                                setMainVal(val)
                                                setParam('full_address', val)
                                                setFullAddress(val)
                                            }}
                                            disbled={isAdmin ? false : true}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Control
                                            value={params.projectNo}
                                            onChange={(e) => setParam("projectNo", e.target.value)}
                                            type="text"
                                            placeholder={isGerm ? DE.project_No : EN.project_No}
                                        />
                                        {errors.projectNo && (
                                            <small className="pl-2 text-danger">
                                                {errors.projectNo}
                                            </small>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="select_coustom border-0">
                                        {!paramsLoaded.buildingType && (
                                            <a
                                                className="register_role pointer text-truncate position-relative"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (isAdmin)
                                                        setShowDialog(true);
                                                }}
                                                style={{ backgroundColor: isAdmin ? null : "#e9ecef" }}
                                            >
                                                {!displayTypes && (
                                                    <span>
                                                        {isGerm ? DE.building_type.replace('*', '') : EN.building_type.replace('*', '')}{" "}
                                                    </span>
                                                )}
                                                {displayTypes && (
                                                    <span>{displayTypes}</span>
                                                )}
                                                <BsChevronDown />
                                            </a>
                                        )}
                                        {paramsLoaded.buildingType && params.buildingType && (
                                            <a
                                                className="register_role pointer text-truncate position-relative"
                                                style={{ background: "#e9ecef" }}
                                            >
                                                {!displayTypes && (
                                                    <span>
                                                        {isGerm ? DE.building_type.replace('*', '') : EN.building_type.replace('*', '')}{" "}
                                                    </span>
                                                )}
                                                {displayTypes && (
                                                    <span>{displayTypes}</span>
                                                )}
                                                <BsChevronDown />
                                            </a>
                                        )}
                                        {errors.buildingType && (
                                            <small className="pl-2 text-danger">
                                                {errors.buildingType}
                                            </small>
                                        )}
                                    </Form.Group>
                                </>
                                :
                                <></>
                        }

                        <Button
                            className={isInternal ? "btn request-btnx" : "btn request-btn"}
                            type="submit"
                            style={{
                                // color: isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color,
                                // backgroundColor: isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color,
                            }}

                        >
                            {isGerm ? DE.update : EN.update}
                        </Button>
                    </Form>
                    <p className="mandatory">
                        {isGerm ? DE.mandatory_fiels : EN.mandatory_fiels}*
                    </p>
                </div>
            </div>
            {showDialog && (
                <BuildingTypesDialog
                    ctom
                    handleClose={(e) => setShowDialog(false)}
                    setParam={setParam}
                    setTypesToDisplay={setDisplayTypes}
                    defaultBuildings={defultTyesArr}
                />
            )}

            {showCompanyDialog && (
                <CompanyListDialog
                    handleClose={(e) => setCompanyDialog(false)}
                    setParam={setParam}
                    handleReJoinCompany={() => { }}
                    companies={companies}
                />
            )}

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    const {
        projects,
        openProjects,
        updateProjectActionFire,
        openBottomProjects,
        projectRequestCount,
        isOpenByOwner,
    } = state;
    return {
        projects: projects,
        updateProjectActionFire: updateProjectActionFire,
        openProjects: openProjects,
        openBottomProjects: openBottomProjects,
        projectRequestCount: projectRequestCount,
        isOpenByOwner: isOpenByOwner,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        handleDrawer: (open) => dispatch({ type: TOGGLE_DRAWER, open })
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditProject));

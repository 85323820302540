import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { BsX } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { SET_VIEWER_IMAGES } from '../store';
import { BASE_URL } from '../helper/constants';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import VideoPlayers from './VideoPlayers';
import ReactPlayer from 'react-player'

function FullScreenImageViewer({ reduxImages }) {
    const dispatch = useDispatch()
    const [images, setImges] = useState([])
    let ind = useSelector(e => e.initalIndex)
    const initalIndex = (ind || 0)
    const [selectedImage, setSelectedImage] = useState('')
    console.log(reduxImages, initalIndex)
    useEffect(() => {
        if (reduxImages && reduxImages.length > 0) {
            let xx = reduxImages.map((e, i) => {
                let evd = reduxImages[initalIndex]
                if (evd.videoType) {
                    setSelectedImage({ url: evd.href, isVideo: true, videoType: evd.videoType })
                }
                else
                    setSelectedImage((evd.local || evd.url) ? evd.url ? evd.url : Array.isArray(evd.fileUrl) ? evd.fileUrl[0] : evd.fileUrl : BASE_URL + (Array.isArray(evd.fileUrl) ? evd.fileUrl[0] : evd.fileUrl))
                if (e.size || e.videoType) {
                    return {
                        videoType: e.videoType,
                        url: e.url ? e.url : e.href,
                        local: true,
                        value: e.local ? Array.isArray(e.fileUrl) ? e.fileUrl[0] : e.fileUrl : BASE_URL + (Array.isArray(e.fileUrl) ? e.fileUrl[0] : e.fileUrl),
                        selected: i == initalIndex ? true : false
                    }
                }
                else
                    return {
                        value: e.local ? Array.isArray(e.fileUrl) ? e.fileUrl[0] : e.fileUrl : BASE_URL + (Array.isArray(e.fileUrl) ? e.fileUrl[0] : e.fileUrl),
                        selected: i == initalIndex ? true : false
                    }
            })
            setImges([...xx])
        }
    }, [reduxImages])
    const _selectImage = (e, i) => {
        let tmp = [...images]
        tmp = tmp.map((x, y) => {
            if (y == i) {
                if (x.videoType) {
                    setSelectedImage({ url: x.url, isVideo: true, videoType: x.videoType })
                }
                else
                    setSelectedImage(x.local ? x.url : x.value)
                return { ...x, selected: true }
            }
            else {
                return { ...x, selected: false }
            }
        })
        setImges([...tmp])
    }

    const _renderVideoPlayerPlaceholder = (e) => {
        return (
            <div
                onClick={() => {

                }}
                style={{
                    height: "100%",
                    width: "100%",
                    position: "relative"
                }}
            >
                <ReactPlayer
                    light={true}
                    url={e.url}
                    controls={false}
                    muted={true}
                    height={'100%'}
                    width={'100%'}
                    playIcon={<></>}
                />
                {/* <img style={{ height: "100%", width: "100%", objectFit: "cover" }} src={process.env.PUBLIC_URL + '/assets/img/BG-Video-Feed.png'} /> */}
                {/* <PlayCirIcon height={40} width={40} /> */}
                <div style={{ alignItems: "center", zIndex: 10, position: "absolute", display: "flex", flexDirection: "column", top: 0, left: 0, right: 0, bottom: 0 }}>
                    <div style={{ flex: 1 }} />
                    <img style={{ height: 30, width: 30 }} src={process.env.PUBLIC_URL + '/assets/img/DN4X.png'} />
                    <div style={{ flex: 1 }} />
                </div>
            </div>
        );
    };
    return (
        <div style={{ position: "absolute", left: 0, right: 0, bottom: 0, top: 0, zIndex: 1000, background: '#111B22', display: "flex", flexDirection: "column" }}>
            <div onClick={() => {
                dispatch({ type: SET_VIEWER_IMAGES, ViewerImages: [] })
            }} style={{ position: "absolute", alignSelf: "flex-end", right: 0 }}>
                <div style={{ marginTop: 20, marginRight: 20 }}>
                    <BsX color='#fff' size={40} />
                </div>
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {!selectedImage?.videoType ?
                    <Zoom>
                        <img
                            src={selectedImage}
                            style={{ maxHeight: '480px', objectFit: 'contain' }}
                        />
                    </Zoom>
                    :
                    <div style={{ width: "40%", height: '40%' }} >
                        <VideoPlayers
                            url={selectedImage.url}
                            type={selectedImage.videoType}
                            isPlaying={''}
                            fileType={''}
                            local={selectedImage.local}
                            article={{}}
                            isC={false}
                            index={0}
                            removeImage={(e) => { }}
                        />
                    </div>
                }
            </div>
            <div style={{ overflowX: "scroll", display: "flex" }}>
                {images.map((e, i) => {
                    return (
                        <div onClick={() => {
                            _selectImage(e, i)
                        }} className='myImDiv' style={{ overflow: "hidden", height: 100, marginRight: i == images.length - 1 ? 10 : 0, width: 100, border: e.selected ? "4px solid #667681" : "4px solid #2A3942", marginLeft: 20, borderRadius: 10 }}>
                            {e.videoType ? _renderVideoPlayerPlaceholder(e) :
                                <img
                                    className='myImIm'
                                    src={e.local ? e.url : e.value}
                                    style={{ height: "100%", width: "100%", objectFit: 'cover' }}
                                />
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FullScreenImageViewer